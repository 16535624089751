<template>
  <div class="card spezialcard has-background-black-ter">
    <div class="card-content">
      <p class="subtitle has-text-grey-lighter">
        Average Relative Pressure
      </p>
      <section>
        <b-progress
          :value="45"
          type="is-primary"
          format="raw"
          size="is-medium"
          :show-value="false"
        >
          3 out of 4
        </b-progress>
      </section>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.spezialcard {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
