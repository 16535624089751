<template>
  <b-field label="Select datetime">
    <b-datetimepicker
      v-model="datetime"
      :datetime-formatter="date => dateTimeFormatter(date)"
      placeholder="Click to select..."
    >
      <template slot="left">
        <button class="button is-primary" @click="datetime = new Date()">
          <b-icon icon="clock"></b-icon>
          <span>Now</span>
        </button>
      </template>
      <template slot="right">
        <button class="button is-danger" @click="datetime = null">
          <b-icon icon="close"></b-icon>
          <span>Clear</span>
        </button>
      </template>
    </b-datetimepicker>
  </b-field>
</template>

<script>
import moment from "../../node_modules/moment";
export default {
  data() {
    return {
      datetime: new Date()
    };
  },
  methods: {
    dateTimeFormatter(inputDate) {
      return moment(inputDate).format("MMMM Do YYYY, h:mm:ss");
    }
  }
};
</script>

<style></style>
