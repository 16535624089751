<template>
  <div>
    <div class="columns is-mobile is-centered">
      <div class="column is-10-mobile is-three-quarters-tablet">
        <div class="card has-background-grey-dark has-text-grey-lighter">
          <div class="card-content">
            Currently installed Version:
            <h3 class="has-text-primary">{{ getVersion }}</h3>
          </div>
        </div>
        <br />

        <div class="card has-background-grey-dark has-text-grey-lighter">
          <div class="card-content">
            <div>
              Available Devices:
            </div>
            <div v-for="device in getAllDevices" :key="device.id">
              <settings-card :device="device" />
            </div>
            <br />
            <div v-if="isConnecting">
              <div>{{ connectionMessage }}</div>
              <b-progress :value="progress"></b-progress>
            </div>
            <div class="block">
              <div v-if="hasBluetooth">
                <b-button
                  @click="registerNewDevice"
                  type="is-primary"
                  expanded=""
                >
                  Search for devices
                </b-button>
              </div>
            </div>
            <!--b-button @click="sendToWorker" type="is-primary" expanded="">
              Check Worker
            </b-button-->
            <div class="block">
              <b-field>
                <b-switch v-model="getVrEnabled">
                  VR Experience (Experimental)
                </b-switch>
              </b-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UpdateProgressModal from "@/components/UpdateProgressModal";
import SettingsCard from "@/components/SettingsCard";

export default {
  components: {
    SettingsCard
  },
  data() {
    return {
      hasBluetooth: false
    };
  },
  computed: {
    getVersion() {
      return process.env.VUE_APP_VERSION;
    },
    getAllDevices() {
      return this.$store.state.devices;
    },
    progress() {
      return this.$store.state.connectionProgress;
    },
    connectionMessage() {
      return this.$store.state.connectionMessage;
    },
    isConnecting() {
      return this.$store.state.isConnecting;
    },
    isUpdating() {
      if (this.$store.state.updateProgress === null) {
        return false;
      } else {
        return true;
      }
    },
    isSynchronizing() {
      if (this.$store.getters.deviceSynchronizing) {
        return this.$store.getters.deviceSynchronizing;
      } else {
        return false;
      }
    },
    getVrEnabled: {
      get: function() {
        return this.$store.state.isVREnabled;
      },
      set: function() {
        this.$store.dispatch("changeVrEnabled", !this.$store.state.isVREnabled);
      }
    }
  },
  watch: {
    isUpdating: function() {
      this.showDeviceUpdate();
    }
  },
  mounted() {
    if (!navigator.bluetooth) {
      this.showToast(
        "Web Bluetooth is not supported. Please use chrome browser release 56 or newer"
      );
    } else {
      this.hasBluetooth = true;
    }
  },
  methods: {
    registerNewDevice() {
      this.$store.dispatch("addDevice").catch(err => {
        console.log(err);
        this.showToast(err.toString(), "is-danger");
      });
    },
    updateDevice(device) {
      this.$store.dispatch("updateDevice", device);
    },
    showDeviceUpdate() {
      this.$buefy.modal.open({
        parent: this,
        component: UpdateProgressModal,
        hasModalCard: true,
        customClass: "custom-class custom-class-2"
      });
    },
    deleteDevice(device) {
      this.demoDevices.forEach((element, index, array) => {
        if (element.id === device.id) {
          array.splice(index, 1);
        }
      });
    },
    showToast(message, colorCode) {
      this.$buefy.toast.open({
        duration: 5000,
        message: message,
        position: "is-bottom",
        type: colorCode
      });
    },
    sendToWorker() {
      console.log("sendToWorker");
      this.getWorker()
        .then(msg => console.log(msg))
        .catch(error => console.log(error));
    },
    getWorker() {
      return new Promise(function(resolve, reject) {
        var messageChannel = new MessageChannel();
        messageChannel.port1.onmessage = function(event) {
          if (event.data.error) {
            reject(event.data.error);
          } else {
            resolve(event.data);
          }
        };

        // This sends the message data as well as transferring
        // messageChannel.port2 to the service worker.
        // The service worker can then use the transferred port to reply
        // via postMessage(), which will in turn trigger the onmessage
        // handler on messageChannel.port1.
        // See
        // https://html.spec.whatwg.org/multipage/workers.html#dom-worker-postmessage
        navigator.serviceWorker.controller.postMessage({ action: "anton" }, [
          messageChannel.port2
        ]);
      });
    }
  }
};
</script>

<style></style>
