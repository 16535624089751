const getters = {
  deviceAlreadyRegistered: state => newDevice => {
    for (var device in state.devices) {
      if (device.esp32Service.device.id === newDevice.esp32Service.device.id) {
        return true;
      }
    }
    return false;
  },
  deviceSynchronizing: state => {
    var synchronizingBox = [];
    for (var deviceInStore in state.devices) {
      console.log(state.devices);
      synchronizingBox.push({
        id: deviceInStore.id,
        synchronizing: deviceInStore.loadingHistoryData
      });
    }
    return synchronizingBox;
  },
  getIsConnected: state => device => {
    var connected = false;
    state.devices.forEach(element => {
      if (element.id === device.id) {
        if ("gatt" in element.device) {
          connected = element.connected;
        }
      }
    });
    return connected;
  },
  getLiveDataByIndex: state => index => {
    if (state.devices.length > index) {
      if (state.devices.connected) {
        console.log('new data arrived')
        return state.devices[index].sensorLiveData
      }
      return [0,0,0]
    } else {
      return 
    }
    
  }
};

export default getters;
