import devicedb from "../bt/devicedb";
import Vue from "vue";

const mutations = {
  addDevice(state, payload) {
    var foundIndex = -1;
    state.devices.forEach((device, index) => {
      if (device.id === payload.id) {
        foundIndex = index;
      }
    });
    console.log("foundIndex: " + foundIndex);
    if (foundIndex === -1) {
      state.devices.push(payload);
    } else {
      payload.sensorHistory = state.devices[foundIndex].sensorHistory;
      Vue.set(state.devices, foundIndex, payload);
    }
  },
  addDataPoint(state, payload) {
    if (!state.dataStore.batterylevel) {
      state.dataStore.batterylevel = [];
    }
    state.dataStore.batterylevel.push(payload.value);
  },
  connectingDevice(state, payload) {
    state.connectionMessage = payload.message;
    state.isConnecting = payload.isConnecting;
  },
  updateProgress(state, payload) {
    state.updateProgress = payload;
  },
  startSensorLiveStreaming(state, id) {
    state.devices.forEach(device => {
      if (device.id === id) {
        device.StartLiveDataStreaming();
      }
    });
  },
  stopSensorLiveStreaming(state, id) {
    state.devices.forEach(device => {
      if (device.id === id) {
        device.stopLiveDataStreaming();
      }
    });
  },
  loadHistoryDatapoints(state) {
    if (!state.indexedDBDatapointsLoaded) {
      var storeNames = devicedb.objectStoreNames();
      storeNames.then(storeNames => {
        Array.from(storeNames).forEach(storeName => {
          var alreadyInStore = false;
          state.deviceData.forEach(deviceDataItem => {
            if (deviceDataItem.id === storeName) {
              alreadyInStore = true;
            }
          });
          if (!alreadyInStore) {
            devicedb.getallDatapointsStoreName(storeName).then(storeData => {
              state.deviceData.push({ id: storeName, datapoints: storeData });
            });
          }
        });
      });
    }
  },
  isOnline(state) {
    state.online = true;
  },
  isOffline(state) {
    state.online = false;
  },
  changeVrEnabled(state, payload) {
    state.isVREnabled = payload;
  },
  loadindexDBData(state, payload) {
    state.devices.forEach(device => {
      if (device.id === payload.id) {
        Vue.set(device, "sensorHistory", payload.result);
      }
    });
  },
  deleteDeviceFromStore(state, payload) {
    state.devices.forEach((device, index) => {
      if (device.id === payload.id) {
        state.devices.splice(index, 1);
      }
    });
  },
  changeTabIndex(state, payload) {
    state.tabIndex = payload
  },
  changeToSettingsTab(state) {
    if (state.isVREnabled) {
      state.tabIndex = 4
    } else {
      state.tabIndex = 3
    }
  }
};

export default mutations;
