<template>
  <div
    class="modal-card has-background-grey-dark has-text-grey-lighter"
    style="width: auto"
  >
    <header class="card-header">
      <p class="card-header-title has-text-primary is-size-4">
        Device Info
      </p>
      <a href="#" class="card-header-icon" aria-label="more options">
        <span class="icon">
          <i class="fas fa-angle-down" aria-hidden="true"></i>
        </span>
      </a>
    </header>
    <div class="card-content">
      <div class="columns is-variable is-1 is-multiline">
        <div class="column is-half">
          <!-- ###################### Device Info ###################### -->
          <div class="card has-background-black-ter">
            <header class="card-header">
              <p class="card-header-title has-text-grey-lighter">
                Device
              </p>
              <a href="#" class="card-header-icon" aria-label="more options">
                <span class="icon">
                  <i class="fas fa-angle-down" aria-hidden="true"></i>
                </span>
              </a>
            </header>
            <div class="card-content">
              <div class="content">
                <table class="table is-fullwidth">
                  <tbody>
                    <tr>
                      <th>
                        Name
                      </th>
                      <td>
                        {{ device.deviceName }}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        Hardware Version
                      </th>
                      <td>
                        {{ device.currentHardwareVersion }}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        Software Version
                      </th>
                      <td>
                        {{ device.softwareVersion }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <footer class="card-footer">
              <a
                href="#"
                class="card-footer-item has-text-primary"
                @click="changeDeviceName()"
                >Change</a
              >
              <a
                href="#"
                class="card-footer-item has-text-primary"
                @click="lcdTurnOnOff()"
                >Turn LCD off</a
              >
              <a
                href="#"
                class="card-footer-item has-text-primary"
                @click="lcdTurnOnOff()"
                >Factory Reset</a
              >
            </footer>
          </div>
        </div>
        <div class="column is-half">
          <!-- ###################### Sensor Info ###################### -->
          <div class="card has-background-black-ter">
            <header class="card-header">
              <p class="card-header-title has-text-light">
                Sensor Info
              </p>
              <a href="#" class="card-header-icon" aria-label="more options">
                <span class="icon">
                  <i class="fas fa-angle-down" aria-hidden="true"></i>
                </span>
              </a>
            </header>
            <div class="card-content">
              <div class="content has-text-light">
                <table class="table is-fullwidth">
                  <tbody>
                    <tr>
                      <th>
                        Number of Datapoints [-]
                      </th>
                      <td>
                        {{ device.numberOfDatapoints }}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        Number of Sensors [-]
                      </th>
                      <td>
                        3
                      </td>
                    </tr>
                    <tr>
                      <th>
                        Pressure Limit [kg]
                      </th>
                      <td>
                        {{ device.pressureLimit }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <footer class="card-footer">
              <a
                href="#"
                class="card-footer-item has-text-primary"
                @click="getDataPoints()"
                >Synchronize Data</a
              >
              <a
                href="#"
                class="card-footer-item has-text-primary"
                @click="openNewPressureDialog()"
                >Set Limit</a
              >
            </footer>
          </div>
        </div>

        <div class="column is-full">
          <div class="card has-background-black-ter">
            <div class="card-content">
              <div class="content">
                <article class="media">
                  <figure class="media-left">
                    <p class="image is-64x64">
                      <img src="@/assets/battery-grey.svg" />
                    </p>
                  </figure>
                  <div class="media-content">
                    <div class="content">
                      <div
                        class="subclasstitle has-text-weight-bold has-text-grey-lighter"
                      >
                        Battery Info
                      </div>
                      <b-progress
                        :type="batteryPercent"
                        :max="1"
                        :value="getBatteryPercent"
                        :show-value="true"
                        format="percent"
                      >
                      </b-progress>
                      <div class="has-text-grey-lighter">
                        Battery Voltage:
                        {{
                          device.batteryVoltage[
                            device.batteryVoltage.length - 1
                          ]
                        }}
                      </div>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>

        <div class="column is-full">
          <div class="card has-background-black-ter">
            <div class="card-content">
              <div class="content">
                <article class="media">
                  <figure class="media-left">
                    <p class="image is-64x64">
                      <img src="@/assets/microchip-grey.svg" />
                    </p>
                  </figure>
                  <div class="media-content">
                    <div class="content">
                      <div
                        class="subclasstitle has-text-weight-bold has-text-grey-lighter"
                      >
                        Storage Info
                      </div>
                      <b-progress
                        :type="storagePercent"
                        :max="1"
                        :value="device.spiffsInfo[0] / device.spiffsInfo[1]"
                        :show-value="true"
                        format="percent"
                      >
                      </b-progress>
                      <div class="has-text-grey-lighter">
                        Used Storage [bytes]: {{ device.spiffsInfo[0] }} of
                        {{ device.spiffsInfo[1] }}
                      </div>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>

        <div class="column is-full">
          <div class="card has-background-black-ter">
            <header class="card-header">
              <p class="card-header-title has-text-grey-lighter">
                Force Feedback
              </p>
              <a href="#" class="card-header-icon" aria-label="more options">
                <span class="icon">
                  <i class="fas fa-angle-down" aria-hidden="true"></i>
                </span>
              </a>
            </header>
            <div class="card-content">
              <div class="content">
                <b-field label="Intensity [%]">
                  <b-slider :tooltip="true" v-model="intensity" lazy>
                  </b-slider>
                </b-field>

                <b-field label="Duration">
                  <b-slider
                    :min="0"
                    :max="500"
                    :tooltip="false"
                    v-model="duration"
                    lazy
                  >
                    <b-slider-tick :value="0">Off</b-slider-tick>
                    <b-slider-tick :value="50">Short</b-slider-tick>
                    <b-slider-tick :value="200">Medium</b-slider-tick>
                    <b-slider-tick :value="500">Long</b-slider-tick>
                  </b-slider>
                </b-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <a class="card-footer-item" @click="closeModal()">Close</a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    device: {
      type: Object
    }
  },
  data() {
    return {
      intensity: 0,
      duration: 0
    };
  },
  methods: {
    lcdTurnOnOff() {
      this.device.lcdOnOff();
    },
    getDataPoints() {
      this.device.GetHistoryData();
    },
    changeDeviceName() {
      this.$buefy.dialog.prompt({
        message: "New Device Name",
        inputAttrs: {
          placeholder: this.device.deviceName,
          maxlength: 15
        },
        trapFocus: true,
        onConfirm: userInput => console.log("New device name is: " + userInput)
      });
    },
    setNewPressureLimit(newLimit) {
      this.device.setMaxPressureLimit(newLimit);
    },
    openNewPressureDialog() {
      this.$buefy.dialog.prompt({
        message: "Set new Pressure Limit",
        inputAttrs: {
          placeholder: "55.4",
          maxlength: 10
        },
        confirmText: "Set",
        trapFocus: true,
        onConfirm: value => this.setNewPressureLimit(value)
      });
    },
    setVibrationMotorIntensity() {
      this.device.setVibrationMotorIntensity(this.intensity);
    },
    setVibrationMotorDuration() {
      this.device.setVibrationMotorDuration(this.duration);
    },
    closeModal() {
      console.log("sdasdasdsd");
      this.$parent.close();
    }
  },
  mounted() {
    this.duration = this.device.vibrationMotorDuration;
    this.intensity = this.device.vibrationMotorIntensity;
  },
  computed: {
    batteryPercent() {
      var battery = this.device.batteryLevel[0];
      if (battery > 0.3) {
        return "is-success";
      } else if (battery > 0.15) {
        return "is-warning";
      } else {
        return "is-danger";
      }
    },
    storagePercent() {
      var storage = this.device.spiffsInfo[0] / this.device.spiffsInfo[1];
      if (storage < 0.7) {
        return "is-success";
      } else if (storage < 0.85) {
        return "is-warning";
      } else {
        return "is-danger";
      }
    },
    getBatteryPercent() {
      if (this.device.batteryState !== "CHARGING") {
        if (this.device.batteryLevel.length > 0) {
          return this.device.batteryLevel[this.device.batteryLevel.length - 1];
        } else {
          return 0;
        }
      } else {
        return undefined;
      }
    }
  },
  watch: {
    intensity: function() {
      console.log("intensity changed: " + this.intensity);
      this.setVibrationMotorIntensity();
    },
    duration: function() {
      console.log("duration changed: " + this.duration);
      this.setVibrationMotorDuration();
    }
  }
};
</script>

<style scoped>
.card-content {
  overflow: auto;
}

.card {
  height: 100%;
  display: flex;
  flex-direction: column;
  color: #dbdbdb !important;
}

.card-content {
  height: 100%;
}

.card-footer {
  margin-top: auto;
}

.column {
  padding: 0.2rem;
}

.progress-wrapper {
  margin-bottom: 0.4rem;
}

.card-content {
  padding: 1rem;
}

.content figure {
  margin-bottom: 0em;
}

.subclasstitle {
  margin-bottom: 0.5rem;
}

.table {
  background-color: transparent;
  color: white;
}

.label {
  color: #dbdbdb !important;
}

.content table th {
  color: #dbdbdb !important;
}
</style>
