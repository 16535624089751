<template>
  <div>
    <div v-if="getAllDevices.length === 0">
      <no-device />
    </div>
    <div
      class="card has-background-grey-dark has-text-grey-lighter"
      v-for="device in getAllDevices"
      :key="device.id"
    >
      <div class="card-content">
        <p class="title">
          {{ device.deviceName }}
        </p>
        <live-chart :device="device"></live-chart>
      </div>
    </div>
  </div>
</template>

<script>
import LiveChart from "@/components/LiveChart";
import NoDevice from '@/components/NoDevice'

export default {
  components: { LiveChart, NoDevice },
  computed: {
    getAllDevices() {
      return this.$store.state.devices;
    }
  }
};
</script>

<style></style>
