<template>
  <div class="card has-background-grey-dark">
    <div class="card-content">
      <div class="has-text-primary title">{{ device.deviceName }}</div>
      <div v-if="getIsConnected(device)">
        <div class="connected" >
          <div class="has-text-grey-lighter connected-text">
            Connected
          </div>
        </div>
      </div>
      <div v-else>
        <div class="not-connected" >
          <div class="connected-text has-text-grey-lighter">
            Not Connected
          </div>
        </div>
      </div>
      <div>
        <div v-if="getIsConnected(device)" class="battery">
          <div
            :class="setBatteryColor(device)"
            :style="
              setBatteryLength(
                device.batteryLevel[device.batteryLevel.length - 1]
              )
            "
          ></div>
        </div>
      </div>
      <div>
        <br />
        <div class="columns is-1 is-multiline is-mobile">
          <div class="column is-half-mobile is-one-quarter-tablet">
            <max-absolute-pressure></max-absolute-pressure>
          </div>
          <div class="column is-half-mobile is-one-quarter-tablet">
            <max-relative-pressure></max-relative-pressure>
          </div>
          <div class="column is-half-mobile is-one-quarter-tablet">
            <average-absolute-pressure></average-absolute-pressure>
          </div>
          <div class="column is-half-mobile is-one-quarter-tablet">
            <average-relative-pressure></average-relative-pressure>
          </div>
        </div>
        <div>
          <overview-chart :device="device" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaxAbsolutePressure from "../components/MaxAbsolutePressureCard";
import MaxRelativePressure from "../components/MaxRelativePressureCard";
import AverageAbsolutePressure from "../components/AverageAbsolutePressureCard";
import AverageRelativePressure from "../components/AverageRelativePressureCard";
import OverviewChart from "../components/OverviewChart";
export default {
  props: {
    device: {
      type: Object
    }
  },
  components: {
    MaxAbsolutePressure,
    MaxRelativePressure,
    AverageAbsolutePressure,
    AverageRelativePressure,
    OverviewChart
  },
  methods: {
    getIsConnected(device) {
      return this.$store.getters.getIsConnected(device);
    },
    setBatteryColor(level) {
      if (level.batteryState !== "CHARGING") {
        if (level.batteryLevel[level.batteryLevel.length - 1] > 0.6) {
          return "battery-level high";
        } else if (level.batteryLevel[level.batteryLevel.length - 1] > 0.3) {
          return "battery-level medium";
        } else {
          return "battery-level low";
        }
      } else {
        return "battery-level recharge";
      }
    },
    setBatteryLength(level) {
      if (level) {
        return "width:" + level[(level, length - 1)] + "%";
      } else {
        return "width:0%";
      }
    },
  }
};
</script>

<style>
.connected {
  position: relative;
  height: 15px;
  width: 15px;
  background-color: #66cd00;
  border-radius: 50%;
  display: inline-block;
}

.not-connected {
  height: 15px;
  width: 15px;
  background-color: #ff3333;
  border-radius: 50%;
  display: inline-block;
}
.connected-text {
  position: relative;
  top: -4px;
  left: 20px;
  min-width: 150px;
}
</style>
