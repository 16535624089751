<template>
  <div class="columns is-centered">
    <div class="column is-four-fifths">
      <a-scene
        renderer="colorManagement: true;"
        init-scene
        embedded>
        <a-entity v-for="device in devices" :key="device.id">
          <foot-right v-if="devices" :device="device"/>
        </a-entity>
        <a-sky color="#ECECEC"></a-sky>
      </a-scene>
    </div>
  </div>
</template>

<script>
import '../../vr/background-gradient'
import '../../vr/init-scene'
import FootRight from '@/components/FootRightVR'

export default {
  components: {
    FootRight
  },
  data() {
    return {
      isVisible: false,
      device: null
    }
  },
  mounted() {
    // this.startStreaming()
    
    /*
    setInterval(() => {
      const random = Math.random() * 5;
      const random1 = Math.random() * 5;
      const random2 = Math.random() * 5;
      this.updateBar(random, random1, random2)
    }, 1000)
    */
    
    /*
    setInterval(() => {
      this.updateModel()
    }, 10)
    */
  },
  methods: {
  },
  computed: {
    devices: function() {
      return this.$store.state.devices
    }
  }
}
</script>

<style>
a-scene {
  z-index: 100;
  height: 85vh;
  width: 100%;
}
</style>
