/* global AFRAME, THREE */
AFRAME.registerComponent('pressurebar', {
  init: function () {
    this.floor = 0
    this.parent = this.el

    this.initRuler()

    var cylinder = this.cylinder = document.createElement('a-cylinder');
    cylinder.setAttribute('radius', 0.59);
    cylinder.setAttribute('height', 1);
    this.parent.appendChild(cylinder)

    var topText = this.topText = document.createElement('a-entity');
    topText.setAttribute('text', 'align: center; width: 10;');
    topText.setAttribute('position', new THREE.Vector3( 1.2, 5 + 0.2, 0 ));
    this.parent.appendChild(topText)
  },
  update: function (heigth, color) {
    if (this.parent) {
      this.cylinder.object3D.scale.y = heigth;
      var newy = heigth/2
      this.cylinder.object3D.position.y = newy

      this.cylinder.setAttribute('color', color)

      this.topText.setAttribute('text', 'value: '+ parseFloat(heigth).toFixed(1).toString() + ' kg;');
      this.topText.object3D.position.y = heigth
      // this.modelEl.object3D.updateMatrixWorld();
    }
  },
  initRuler() {
    var verticalLine = this.verticalLine = document.createElement('a-entity');
    verticalLine.setAttribute('line', {start: new THREE.Vector3( 0, 0, 0 ), end: new THREE.Vector3( 0, 5, 0 )});
    this.parent.appendChild(verticalLine)

    var topLine = this.topLine = document.createElement('a-entity');
    topLine.setAttribute('line', {start: new THREE.Vector3( -0.5, 5, 0 ), end: new THREE.Vector3( 0.5, 5, 0 )});
    this.parent.appendChild(topLine)

    /*
    var topText = this.topText = document.createElement('a-entity');
    topText.setAttribute('text', 'value: 50kg; align: center; width: 10;');
    topText.setAttribute('position', new THREE.Vector3( 0.5, 5 + 0.2, 0 ));
    this.parent.appendChild(topText)
    */
    /*
    var positiony = 4
    var segment1 = this.segment1 = document.createElement('a-entity');
    segment1.setAttribute('line', {start: new THREE.Vector3( -0.3, positiony, 0 ), end: new THREE.Vector3( 0.3, positiony, 0 )});
    this.parent.appendChild(segment1)

    positiony = 3
    var segment2 = this.segment2 = document.createElement('a-entity');
    segment2.setAttribute('line', {start: new THREE.Vector3( -0.3, positiony, 0 ), end: new THREE.Vector3( 0.3, positiony, 0 )});
    this.parent.appendChild(segment2)

    positiony = 2
    var segment3 = this.segment3 = document.createElement('a-entity');
    segment3.setAttribute('line', {start: new THREE.Vector3( -0.3, positiony, 0 ), end: new THREE.Vector3( 0.3, positiony, 0 )});
    this.parent.appendChild(segment3)
    */
  }
})