<template>
  <b-tabs
    position="is-centered"
    size="is-normal"
    type="is-boxed"
    expanded
    v-model="tabIndex"
  >
    <b-tab-item label="Overview" icon="progress-download">
      <overview></overview>
    </b-tab-item>
    <b-tab-item label="Details" icon="magnify">
      <details-tab></details-tab>
    </b-tab-item>
    <b-tab-item label="Live Monitor" icon="chart-line">
      <live-monitor></live-monitor>
    </b-tab-item>
    <b-tab-item v-if="vrEnabled" label="VirtualReality" icon="virtual-reality">
      <virtual-sensor></virtual-sensor>
    </b-tab-item>
    <b-tab-item label="Settings" icon="cog">
      <settings></settings>
    </b-tab-item>
  </b-tabs>
</template>

<script>
import Overview from "../views/tabpages/Overview";
import DetailsTab from "../views/tabpages/Details";
import LiveMonitor from "../views/tabpages/LiveMonitor";
import Settings from "../views/tabpages/Settings";
import VirtualSensor from "../views/tabpages/VirtualSensor";

export default {
  components: { Overview, DetailsTab, LiveMonitor, Settings, VirtualSensor },
  data() {
    return {
    };
  },
  methods: {
    changeTabByArrow(event) {
      switch (event.keyCode) {
        case 37:
          this.goLeft();
          break;
        case 38:
          // str = 'Up Key pressed!';
          break;
        case 39:
          this.goRight();
          break;
        case 40:
          // str = 'Down Key pressed!';
          break;
      }
    },
    getTemplate(label) {
      switch(label) {
        case "Settings":
          return Overview
      }
    }
  },
  mounted() {
    document.addEventListener("keydown", this.changeTabByArrow);
  },
  computed: {
    vrEnabled() {
      return this.$store.state.isVREnabled;
    },
    tabIndex: {
      get: function() {
        return this.$store.state.tabIndex
      },
      set: function(index) {
        this.$store.commit('changeTabIndex', index)
      }
    }
  }
};
</script>

<style></style>
