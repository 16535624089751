<template>
  <section>
    <div>
      <div v-if="getAllDevices.length === 0">
        <no-device />
      </div>
      <div v-else>
        <div v-for="device in getAllDevices" :key="device.id">
          <overview-card :device="device" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import OverviewCard from "@/components/OverviewCard.vue";
import NoDevice from '@/components/NoDevice'

export default {
  name: "Home",
  components: {
    OverviewCard,
    NoDevice
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch("loadHistoryDatapoints");
  },
  methods: {
    getIsConnected(device) {
      return this.$store.getters.getIsConnected(device);
    }
  },
  computed: {
    devicePool() {
      return this.$store.state.deviceData;
    },
    getAllDevices() {
      return this.$store.state.devices;
    }
  }
};
</script>
