<template>
  <div class="card has-background-grey-dark">
    <div class="card-content">
      <div class="block has-text-light">
        No registered Devices Found. Please register device first by clicking on the button below!
      </div>
      <div class="block">
        <b-button type="is-primary" @click="$store.commit('changeToSettingsTab')">
          Register Device
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: 'NoDevice'
}
</script>

<style>

</style>