<template>
  <div id="app">
    <div class="container">
      <br />
      <tab></tab>
    </div>
    <router-view />
  </div>
</template>

<script>
import Tab from "./components/Tabs";
export default {
  components: { Tab },
  data() {
    return {
      showInstallerBtn: false
    };
  },
  methods: {
  },
  created() {
    this.$store.dispatch("checkValidInternetConnection");
  }
};
</script>
<style></style>
