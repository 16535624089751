import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import Buefy from "buefy";
import "../css/darkmode.css";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Buefy, {
  defaultIconPack: "mdi"
});

// load indexed DB entries to Store

store.dispatch("fetchHistoryData");

Vue.config.productionTip = false;

Vue.config.ignoredElements = [
  'a-scene',
  'a-entity',
  'a-camera',
  'a-box',
  'a-sky',
  'a-gltf-model',
  'a-assets',
  'a-asset-item',
  'a-cylinder',
  'a-plane'
]

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
