import Vue from "vue";
import VueRouter from "vue-router";
import MainPage from "../views/MainPage";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Main",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: MainPage
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
