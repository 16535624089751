import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";


Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    devices: [],
    deviceData: [],
    connectionMessage: null,
    isConnecting: false,
    updateProgress: null,
    indexedDBDatapointsLoaded: false,
    fetchedHistoryData: false,
    online: true,
    isVREnabled: false,
    tabIndex: 0
  },
  getters: getters,
  mutations: mutations,
  actions: actions,
  modules: {},
  plugins: [createPersistedState()]
});
