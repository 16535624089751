<template>
  <div class="card">
    <div class="card-content">
      <div class="media">
        <div class="media-left">
          <figure class="image is-48x48">
            <img src="@/assets/footprint.png" alt="Placeholder image" />
          </figure>
        </div>
        <div class="media-content">
          <nav class="level">
            <div class="level-item">
              <div>
                <p class="heading">@Hippenpower</p>
                <p class="title">{{ device.deviceName }}</p>
              </div>
            </div>
            <div class="level-item is-hidden-mobile">
              <div v-if="getIsConnected(device)">
                <div class="connected" >
                  <div class="connected-text">
                    Connected
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="not-connected" >
                  <div class="connected-text">
                    Not Connected
                  </div>
                </div>
              </div>
              <div>
                <div v-if="getIsConnected(device)" class="battery">
                  <div
                    :class="setBatteryColor(device)"
                    :style="
                      setBatteryLength(
                        device.batteryLevel[device.batteryLevel.length - 1]
                      )
                    "
                  ></div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>

      <div class="is-hidden-tablet">
        <div v-if="getIsConnected(device)">
                <div class="connected" >
                  <div class="connected-text">
                    Connected
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="not-connected" >
                  <div class="connected-text">
                    Not Connected
                  </div>
                </div>
              </div>
              <div>
                <div v-if="getIsConnected(device)" class="battery">
                  <div
                    :class="setBatteryColor(device)"
                    :style="
                      setBatteryLength(
                        device.batteryLevel[device.batteryLevel.length - 1]
                      )
                    "
                  ></div>
                </div>
              </div>
      </div>

      <div class="content">
        <div v-if="device.loadingHistoryData">
          Synchronize ... [{{
            (device.loadingHistoryState / device.numberOfDatapoints).toFixed(2)
          }}
          %]
        </div>
        <div class="percentage has-text-grey-lighter"></div>
      </div>
    </div>
    <footer class="card-footer">
      <a class="card-footer-item" @click="deleteDevice(device)">Delete</a>
      <a :class="disabledATag(device)" @click="openCalibrationModal()">Calibrate</a>
      <a :class="disabledATag(device)" @click="showDeviceInfo()">Details</a>
    </footer>
  </div>
</template>

<script>
import ShowDeviceInfo from "../components/ShowDeviceInfo";
import CalibrationModal from "../components/CalibrationModal";

export default {
  props: {
    device: {
      type: Object
    }
  },
  methods: {
    disabledATag(device) {
      if (this.getIsConnected(device)) {
        return "card-footer-item";
      } else {
        return "card-footer-item disabled";
      }
    },
    getIsConnected(device) {
      return this.$store.getters.getIsConnected(device);
    },
    setBatteryColor(level) {
      if (level.batteryState !== "CHARGING") {
        if (level.batteryLevel[level.batteryLevel.length - 1] > 0.6) {
          return "battery-level high";
        } else if (level.batteryLevel[level.batteryLevel.length - 1] > 0.3) {
          return "battery-level medium";
        } else {
          return "battery-level low";
        }
      } else {
        return "battery-level recharge";
      }
    },
    setBatteryLength(level) {
      if (level) {
        return "width:" + level[(level, length - 1)] + "%";
      } else {
        return "width:0%";
      }
    },
    showDeviceInfo() {
      this.$buefy.modal.open({
        parent: this,
        component: ShowDeviceInfo,
        hasModalCard: true,
        fullScreen: false,
        canCancel: true,
        scroll: "keep",
        customClass: "custom-class custom-class-2",
        props: { device: this.device }
      });
    },
    openCalibrationModal() {
      this.$buefy.modal.open({
        parent: this,
        component: CalibrationModal,
        hasModalCard: true,
        fullScreen: false,
        canCancel: true,
        scroll: "keep",
        customClass: "custom-class custom-class-2",
        props: { device: this.device }
      });
    },
    deleteDevice(device) {
      this.$store.dispatch("deleteDevice", device);
    },
    setConnectedCircle(connected) {
      if (connected) {
        return "connected";
      } else {
        return "not-connected";
      }
    },
  }
};
</script>

<style scoped>
a.disabled {
  pointer-events: none;
  color: #ccc;
}
.battery:after {
  background-color: #fff;
  border: 2px solid #000;
  content: "";
  display: block;
  height: 8px;
  position: absolute;
  right: -6px;
  top: 3px;
  width: 3px;
}
.battery {
  background-color: #fff;
  border: 2px solid #000;
  height: 16px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 50px;
  left: -30px;
  top: 35px;
}
.battery .battery-level {
  background-color: #666;
  height: 100%;
}
.battery .battery-level.high {
  background-color: #66cd00;
  animation-duration: 0.5s;
}
.battery .battery-level.medium {
  background-color: #fcd116;
  animation-duration: 0.5s;
}
.battery .battery-level.low {
  background-color: #ff3333;
  animation-duration: 0.5s;
}

.percentage {
  position: absolute;
  color: black;
}
.battery .battery-level.recharge {
  animation-name: chargeanimation;
  animation-duration: 4s;
  animation-iteration-count: infinite;
}

/* charge animation */
@keyframes chargeanimation {
  0% {
    width: 10%;
    background-color: #66cd00;
  }
  100% {
    width: 100%;
    background-color: #66cd00;
  }
}
.list-item {
  margin: 5px;
}

</style>
