<template>
  <div>
    <div v-if="getAllDevices.length === 0">
      <no-device />
    </div>
    <div v-else>
      <div class="columns is-mobile">
        <div class="column is-half-mobile">
          <date-time-selection></date-time-selection>
        </div>
        <div class="column is-half-mobile">
          <date-time-selection></date-time-selection>
        </div>
      </div>
      <detail-chart></detail-chart>
    </div>
  </div>
</template>

<script>
import DateTimeSelection from "@/components/DateTimeSelection";
import DetailChart from "@/components/DetailChart";
import NoDevice from '@/components/NoDevice'

export default {
  components: { DateTimeSelection, DetailChart, NoDevice },
  computed: {
    getAllDevices() {
      return this.$store.state.devices;
    }
  }
};
</script>

<style></style>
