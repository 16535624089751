<template>
  <div class="card spezialcard has-background-black-ter">
    <div class="card-content">
      <live-line-chart
        :chart-data="liveData"
        :options="myOptions"
      ></live-line-chart>
      <br />
    </div>
  </div>
</template>

<script>
import LiveLineChart from "./LiveLineChart.js";
import moment from "moment";

export default {
  components: {
    LiveLineChart
  },
  props: {
    device: null
  },
  data() {
    return {
      dataSensor1: [],
      labelData: [],
      liveData: {
        labels: [0],
        datasets: [
          {
            label: "Sensor1",
            borderColor: "#2c5b9c",
            backgroundColor: "#2c5b9c",
            data: [0]
          },
          {
            label: "Sensor2",
            borderColor: "#2c5b9c",
            backgroundColor: "#2c5b9c",
            data: [0]
          },
          {
            label: "Sensor3",
            borderColor: "#2c5b9c",
            backgroundColor: "#2c5b9c",
            data: [0]
          },
          {
            label: "Sum all Sensors",
            borderColor: "#2c5b9c",
            backgroundColor: "#2c5b9c",
            data: [0]
          },
          {
            label: "Limit",
            borderColor: "#f14668",
            data: [0]
          }
        ]
      },
      myOptions: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              }
            }
          ]
        },
        responsive: true,
        maintainAspectRatio: false
      }
    };
  },
  mounted() {
  },
  methods: {
    everySensorData() {
      if (this.labelData.length > 200) {
        this.labelData.shift();
      }

      var limitline = [];
      for (var ii = 0; ii < this.sensorData.length; ii++) {
        limitline.push(this.device.pressureLimit);
      }
      

      this.labelData = this.sensorData.map(function(value) {
        return moment.unix(value[3]).format("YYYY-MM-dd HH:mm:ss");
      });

      this.liveData = {
        labels: this.labelData,
        datasets: [
          {
            label: "Sensor 1",
            borderColor: "#2c5b9c",
            backgroundColor: "#2c5b9c",
            data: this.sensorData.map(function(value) {
              return value[0];
            })
          },
          {
            label: "Sensor 2",
            borderColor: "#c51429",
            backgroundColor: "#c51429",
            data: this.sensorData.map(function(value) {
              return value[1];
            })
          },
          {
            label: "Sensor 3",
            borderColor: "#f0a831",
            backgroundColor: "#f0a831",
            data: this.sensorData.map(function(value) {
              return value[2];
            })
          },
          {
            label: "Sum all Sensors",
            borderColor: "#41bf56",
            backgroundColor: "#41bf56",
            data: this.sensorData.map(function(value) {
              var sum =
                parseFloat(value[0]) +
                parseFloat(value[1]) +
                parseFloat(value[2]);
              return sum;
            })
          },
          {
            label: "Limit",
            borderColor: "#f14668",
            data: limitline
          }
        ]
      };
    }
  },
  computed: {
    sensorData: function() {
      return this.device.sensorHistory;
    }
  },
  watch: {
    sensorData: function() {
      this.everySensorData();
    }
  }
};
</script>

<style></style>
