<template>
  <div class="card spezialcard has-background-black-ter">
    <div class="card-content">
      <b-field>
        <b-select placeholder="Select a character" v-model="selectedOption">
          <option value="sum">Sensor sum</option>
          <option value="ind">Sensors individually</option>
        </b-select>
        <b-select placeholder="Select a character" v-model="selectedDataRange">
          <option value=50>50 Datapoints</option>
          <option value=100>100 Datapoints</option>
          <option value=200>200 Datapoints</option>
        </b-select>
      </b-field>
      <live-line-chart
        :chart-data="liveData"
        :options="myOptions"
      ></live-line-chart>
      <br />
      <div class="buttons">
        <b-button
          type="is-primary"
          @click="pushData()"
          :disabled="!getIsConnected(device)"
          >Start</b-button
        >
        <b-button
          type="is-primary"
          @click="clearInterval()"
          :disabled="!getIsConnected(device)"
          >Stop</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import LiveLineChart from "./LiveLineChart.js";

export default {
  components: {
    LiveLineChart
  },
  props: {
    device: null
  },
  data() {
    return {
      selectedOption: "sum",
      selectedDataRange: 50,
      labelData: [0],
      liveChartInterval: null,
      liveData: {
        labels: [0],
        datasets: [
          {
            label: "Sensor1",
            borderColor: "#2c5b9c",
            backgroundColor: "#2c5b9c",
            data: [0]
          },
          {
            label: "Sensor2",
            borderColor: "#2c5b9c",
            backgroundColor: "#2c5b9c",
            data: [0]
          },
          {
            label: "Sensor3",
            borderColor: "#2c5b9c",
            backgroundColor: "#2c5b9c",
            data: [0]
          },
          {
            label: "Limit",
            borderColor: "#f14668",
            data: [0]
          }
        ]
      },
      myOptions: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              }
            }
          ]
        },
        responsive: true,
        maintainAspectRatio: false
      }
    };
  },
  mounted() {
  },
  methods: {
    pushData() {
      this.$store.dispatch("startSensorLiveStreaming", this.device.id);
      if (this.liveChartInterval !== null) {
        clearInterval(this.liveChartInterval);
      }
      
      this.liveChartInterval = setInterval(this.addRandomData, 100);
    },
    clearInterval() {
      this.$store.dispatch("stopSensorLiveStreaming", this.device.id);
      clearInterval(this.liveChartInterval);
    },
    reduceList(array, secondIndex) {
      var dataArray = []
      array.forEach(element => {
        dataArray.push(element[secondIndex])
      })
      return dataArray
    },
    sumList(array) {
      var dataArray = []
      array.forEach(element => {
        dataArray.push(parseFloat(element[0]) + parseFloat(element[1]) + parseFloat(element[2]))
      })
      return dataArray
    },
    addRandomData() {
      var datasets = [];

      var sensorData = []
      if(this.device.sensorLiveData.length > parseInt(this.selectedDataRange)) {
        var backupData = JSON.parse(JSON.stringify(this.device.sensorLiveData))
        sensorData = backupData.splice(backupData.length - parseInt(this.selectedDataRange), parseInt(this.selectedDataRange))
      } else {
         sensorData = this.device.sensorLiveData
      }

      // build limit line
      var limitline = [];
      for (var ii = 0; ii < sensorData.length; ii++) {
        limitline.push(this.device.pressureLimit);
      }

      switch (this.selectedOption) {
        case "sum":
          datasets = [
            {
              label: "Sensor 1",
              borderColor: "#2c5b9c",
              backgroundColor: "#2c5b9c",
              data: this.sumList(sensorData)
            },
            {
              label: "Limit",
              borderColor: "#f14668",
              data: limitline
            }
          ];
          break;
        case "ind":
          datasets = [
            {
              label: "Sensor 1",
              borderColor: "#2c5b9c",
              backgroundColor: "#2c5b9c",
              data: this.reduceList(sensorData, 0)
            },
            {
              label: "Sensor 2",
              borderColor: "#c51429",
              backgroundColor: "#c51429",
              data: this.reduceList(sensorData, 1)
            },
            {
              label: "Sensor 3",
              borderColor: "#f0a831",
              backgroundColor: "#f0a831",
              data: this.reduceList(sensorData, 2)
            },
            {
              label: "Limit",
              borderColor: "#f14668",
              data: limitline
            }
          ];
          break;
      }
      if (this.labelData.length > this.selectedDataRange) {
        this.labelData.shift();
      }

      this.labelData = Array.from(
        { length: sensorData.length },
        (v, k) => k + 1
      );

      this.liveData = {
        labels: this.labelData,
        datasets: datasets
      };
    },
    getIsConnected(device) {
      return this.$store.getters.getIsConnected(device);
    }
  },
  watch: {
    selectedOption: function() {
      this.addRandomData();
    }
  }
};
</script>

<style></style>
