import devicedb from "../bt/devicedb";
import BluetoothManager from "../bt/manager";
import { DialogProgrammatic as Dialog } from "buefy";

const actions = {
  addDevice(context) {
    return new Promise((resolve, reject) => {
      var newDevice = new BluetoothManager();
      context.commit("connectingDevice", {
        message: "request device services",
        isConnecting: true
      });
      newDevice
        .connect2Device()
        /*
        .then(() => {
          context.commit("connectingDevice", {
            message: "checking for updates",
            isConnecting: true
          });
          newDevice.checkVersion()
          return
        })
        .then(() => {
          if (newDevice.latestCompatibleSoftware === null) {
            context.commit("addDevice", newDevice);
            resolve();
          }
          return;
        })
        .then(() =>
          Dialog.confirm({
            message:
              "Version " +
              newDevice.softwareVersion +
              " is out of date. Update to " +
              newDevice.latestCompatibleSoftware +
              "?",
            confirmText: "Update",
            onConfirm: () => {
              newDevice.prepareForUpdate();
            },
            onCancel: () => {
              return;
            }
          })
        )
        */
        .then(() => {
          context.commit("addDevice", newDevice);
          return newDevice;
        })
        .then(device => {
          // device.GetHistoryData()
          device.getDatapointData();
          resolve();
        })
        .catch(error => {
          reject(error);
        })
        .finally(() =>
          context.commit("connectingDevice", {
            message: null,
            isConnecting: false
          })
        );
    });
  },
  updateDevice(context, toBeUpdatedDevice) {
    toBeUpdatedDevice.checkVersion().then(() =>
      Dialog.confirm({
        message:
          "Version " +
          toBeUpdatedDevice.softwareVersion +
          " is out of date. Update to " +
          toBeUpdatedDevice.latestCompatibleSoftware +
          "?",
        confirmText: "Update",
        onConfirm: () => {
          toBeUpdatedDevice.prepareForUpdate();
        },
        onCancel: () => {
          return;
        }
      })
    );
  },
  addNewData({ commit }, payload) {
    commit("addDataPoint", payload);
  },
  startSensorLiveStreaming({ commit }, id) {
    commit("startSensorLiveStreaming", id);
  },
  stopSensorLiveStreaming({ commit }, id) {
    commit("stopSensorLiveStreaming", id);
  },
  loadHistoryDatapoints({ commit }) {
    commit("loadHistoryDatapoints");
  },
  checkValidInternetConnection({ commit }) {
    window.addEventListener("online", commit("isOnline"));
    window.addEventListener("offline", commit("isOffline"));
  },
  changeVrEnabled({ commit }, payload) {
    commit("changeVrEnabled", payload);
  },
  fetchHistoryData(context) {
    devicedb.objectStoreNames().then(storeNames => {
      context.state.devices.forEach(device => {
        storeNames.forEach(storeName => {
          if (storeName === device.id) {
            devicedb.getAllEntriesFromStore(device.id).then(result => {
              context.commit("loadindexDBData", {
                id: device.id,
                result: result
              });
            });
          }
        });
      });
    });
  },
  deleteDevice({ commit }, payload) {
    commit("deleteDeviceFromStore", payload);
  }
};

export default actions;
