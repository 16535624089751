<template>
  <div class="card spezialcard has-background-black-ter">
    <div class="card-content">
      <p class="subtitle has-text-grey-lighter">
        Max Pressure
      </p>
      <p class="subtitle has-text-primary">
        30 kg
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.spezialcard {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
