/* global AFRAME */
AFRAME.registerComponent('update', {
  schema: {
    rotation: {
      default:[0,0,0]
    }
  },
  init: function () {
    this.model = this.el

    
  },
  setRotation: function() {
    if (this.model) {
      this.model.object3D.rotation.y += 0.001
      this.model.object3D.updateMatrixWorld();
    }
  },

});

export default AFRAME
