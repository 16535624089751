<template>
  <div class="card has-background-grey-dark">
    <div class="card-content">
      <div class="has-text-primary">Updating Device</div>
      <div>
        <br />
        <b-progress
          type="is-primary"
          :value="progress"
          show-value
          format="percent"
          precision="2"
          keep-trailing-zeroes
          size="is-medium"
        ></b-progress>
        <div class="has-text-primary">Please wait ...</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    progress() {
      return this.$store.state.updateProgress;
    },
    connectionMessage() {
      return this.$store.state.connectionMessage;
    }
  }
};
</script>

<style></style>
