<template>
  <div class="card spezialcard has-background-black-ter">
    <div class="card-content">
      <div class="subtitle has-text-white">Foot Left</div>
      <line-chart :chart-data="datacollection" :options="options"></line-chart>
      <b-button type="is-primary" @click="fillData()">Randomize</b-button>
    </div>
  </div>
</template>

<script>
import LineChart from "./LineChart.js";

export default {
  components: {
    LineChart
  },
  data() {
    return {
      datacollection: {},
      options: {
        title: {
          display: true,
          text: "藩と人口"
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                display: true
              }
            }
          ],
          xAxes: [
            {
              gridLines: {
                display: false
              }
            }
          ]
        },
        legend: {
          display: false
        },
        responsive: true,
        maintainAspectRatio: false
      }
    };
  },
  mounted() {
    // this.fillData()
  },
  methods: {
    fillData() {
      this.datacollection = {
        labels: [0, 1, 2, 3, 4],
        datasets: [
          {
            //label: 'Sensor 1',
            borderColor: "#2c5b9c",
            data: [
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt()
            ]
          },
          {
            //label: 'Sensor 2',
            borderColor: "#c51429",
            data: [
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt()
            ]
          },
          {
            //label: 'Sensor 3',
            borderColor: "#f0a831",
            data: [
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt()
            ]
          }
        ]
      };
      console.log("hgaksljhdgajshgd");
    },
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5;
    }
  }
};
</script>

<style></style>
