const DB_NAME = "HistoryData";
// var DB_VERSION = 1;
const OBJECT_STORE_NAME = "HistoryData";
let DB;

export default {
  async getDb(storeName) {
    return new Promise((resolve, reject) => {
      // console.log("OPENING DB", DB);
      let request = window.indexedDB.open(DB_NAME);

      request.onerror = e => {
        console.log("Error opening db", e);
        reject("Error");
      };

      request.onsuccess = e => {
        DB = e.target.result;
        // console.log('objectStoreNames contains ' + storeName + ': ' + DB.objectStoreNames.contains(storeName))
        if (!DB.objectStoreNames.contains(storeName)) {
          var version = parseInt(DB.version);
          DB.close();
          // console.log('open new version: ' + (version + 1))
          let secondRequest = window.indexedDB.open(DB_NAME, version + 1);

          secondRequest.onupgradeneeded = e => {
            // console.log('onupgradeneeded +')
            let database = e.target.result;
            database.createObjectStore(storeName, {
              autoIncrement: true,
              keyPath: "id"
            });
          };

          secondRequest.onsuccess = e => {
            let newdatabase = e.target.result;
            // console.log(newdatabase)
            resolve(newdatabase);
          };
        } else {
          resolve(DB);
        }
      };

      request.onupgradeneeded = e => {
        // console.log("onupgradeneeded");
        let db = e.target.result;
        db.createObjectStore(storeName, {
          autoIncrement: true,
          keyPath: "id"
        });
      };
    });
  },
  async objectStoreNames() {
    let db = await this.getDb("test");
    var storeNames = [];
    // iterate backwards ensuring that length is an UInt32
    for (var i = db.objectStoreNames.length >>> 0; i--; ) {
      storeNames[i] = db.objectStoreNames[i];
    }

    return storeNames;
  },
  async delete(cat) {
    let db = await this.getDb();

    return new Promise(resolve => {
      let trans = db.transaction([OBJECT_STORE_NAME], "readwrite");
      trans.oncomplete = () => {
        resolve();
      };

      let store = trans.objectStore(OBJECT_STORE_NAME);
      store.delete(cat.id);
    });
  },
  async getallDatapointsStoreName(storeName) {
    let db = await this.getDb();

    return new Promise(resolve => {
      let trans = db.transaction([storeName], "readonly");
      trans.oncomplete = () => {
        resolve(cats);
      };

      let store = trans.objectStore(storeName);
      let cats = [];

      store.openCursor().onsuccess = e => {
        let cursor = e.target.result;
        if (cursor) {
          cats.push(cursor.value);
          cursor.continue();
        }
      };
    });
  },

  async getAllEntriesFromStore(storeName) {
    let db = await this.getDb(storeName);
    return new Promise(resolve => {
      var transaction = db.transaction(storeName, "readonly");
      var objectStore = transaction.objectStore(storeName);
      objectStore.getAll().onsuccess = function(event) {
        resolve(event.target.result);
      };
    });
  },

  async saveDevice(storeName, datapoint) {
    console.log("storeName: " + storeName + ", datapoint => " + datapoint);
    let db = await this.getDb(storeName);
    console.log(db);

    return new Promise(resolve => {
      let trans = db.transaction([storeName], "readwrite");
      trans.oncomplete = () => {
        resolve();
      };

      let store = trans.objectStore(storeName);
      store.put(datapoint);
      db.close();
    });
  }
};
