<template>
  <a-entity id="model1" :gltf-model="modelUrl" update init-model>
    <a-entity id="bar2" position="-2.835 0 -1.494" pressurebar></a-entity>
    <a-entity id="bar1" position="-3.690 0 1.201" pressurebar></a-entity>
    <a-entity id="bar3" position="5.371 0 -0.004" pressurebar></a-entity>
  </a-entity>
</template>

<script>
import '../vr/update-position'
import '../vr/init-model'
import '../vr/pressure-bar'
export default {
  props: {
    device: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      modelUrl: process.env.BASE_URL + "shoe_sole_assembly.glb",
      liveChartInterval: null
    }
  },
  mounted() {
  },
  methods: {
    updateModel() {
      var updateMethod = document.getElementById('model1').components.update
      updateMethod.setRotation()
    },
    updateBar(size1, size2, size3) {
      var updateMethod = document.getElementById('bar1').components.pressurebar
      updateMethod.update(size1, this.getColor(size1))

      var updateMethod2 = document.getElementById('bar2').components.pressurebar
      updateMethod2.update(size2, this.getColor(size2))

      var updateMethod3 = document.getElementById('bar3').components.pressurebar
      updateMethod3.update(size3, this.getColor(size3))
    },
    startStreaming() {
      this.$store.dispatch("startSensorLiveStreaming", this.device.id);
      if (this.liveChartInterval !== null) {
        clearInterval(this.liveChartInterval);
      }
      
      this.liveChartInterval = setInterval(this.getData, 100);
    },
    getData() {
      if (this.device.sensorLiveData.length > 0) {
        var lastDatapoint = this.device.sensorLiveData[this.device.sensorLiveData.length - 1]
      this.updateBar(lastDatapoint[0], lastDatapoint[1], lastDatapoint[2])
      }
    },
    getColor(pressure) {
      if(pressure > 4.9) {
        return '#fc4d44'
      } else if (pressure > 3) {
        return '#ffc65d'
      } else {
        return '#67fc67'
      }
    }
  },
  watch: {
    connected: function() {
      console.log('device: ' + this.device.id + ' is connected: ' + this.connected)
      if (this.connected) {
        this.startStreaming()
        setInterval(() => {
          this.updateModel()
        }, 10)
      }
    }
  },
  computed: {
    connected: function() {
      return this.$store.getters.getIsConnected(this.device)
    }
  }
}
</script>

<style>

</style>