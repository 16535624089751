<template>
  <div class="card">
    <header class="card-header">
      <p class="card-header-title">
        Calibration Process
      </p>
    </header>
    <div class="card-content">
      <b-steps
        size="is-large"
        v-model="activeStep"
        :has-navigation="false"
        mobile-mode="compact"
      >
        <b-step-item label="Sensor 1"></b-step-item>
        <b-step-item label="Sensor 2"></b-step-item>
        <b-step-item label="Sensor 3"></b-step-item>
      </b-steps>
      <b-image :src="require('@/assets/powersole.jpg')"> </b-image>
      <div v-if="activeStep === 0" id="sensor1"></div>
      <b-progress :value="getActualValue" show-value></b-progress>
    </div>
    <footer class="card-footer">
      <a class="card-footer-item" @click="closeModal()">Close</a>
      <a class="card-footer-item" @click="nexStep()">{{ nextButton }}</a>
    </footer>
  </div>
</template>

<script>
export default {
  props: {
    device: {
      type: Object,
      default: () => {}
    }
  },
  components: {},
  data() {
    return {
      activeStep: 0,
      nextButton: "Next Step"
    };
  },
  mounted() {
    this.startLiveStream()
  },
  methods: {
    closeModal() {
      this.device.stopLiveDataStreaming()
      this.$parent.close();
    },
    nexStep() {
      if (this.activeStep === 2) {
        this.closeModal();
      } else if (this.activeStep === 1) {
        this.nextButton = "Finish";
      }

      this.activeStep++;
    },
    startLiveStream() {
      this.device.StartLiveDataStreaming()
    }
  },
  computed: {
    getActualValue() {
      return this.device.sensorLiveData[this.device.sensorLiveData.length - 1][this.activeStep]
    }
  }
};
</script>

<style>
#sensor1 {
  position: absolute;
  z-index: 10;
  border-radius: 50%;
  animation: mymove 1s infinite;
  animation-direction: alternate;
}

@keyframes mymove {
  0% {
    top: 300px;
    left: 100px;
    width: 100px;
    height: 100px;
    box-shadow: 0px 0px 0px 10px red;
  }
  100% {
    top: 275px;
    left: 75px;
    width: 150px;
    height: 150px;
    box-shadow: 0px 0px 0px 10px red;
  }
}
</style>
