import store from "../store";
import { SnackbarProgrammatic as Snackbar } from "buefy";
import devicedatadb from "../bt/devicedb";

class BluetoothManager {
  constructor() {
    this.device = null;
    this.connected = false;
    this.id = "N/A";

    this.myESP32 = "d804b643-6ce7-4e81-9f8a-ce0f699085eb";

    this.otaServiceUuid = "c8659210-af91-4ad3-a995-a58d6fd26145";
    this.versionCharacteristicUuid = "c8659212-af91-4ad3-a995-a58d6fd26145";
    this.fileCharacteristicUuid = "c8659211-af91-4ad3-a995-a58d6fd26145";

    this.updateReady = false;

    this.esp32Device = null;
    this.esp32Service = null;
    this.readyFlagCharacteristic = null;
    this.dataToSend = null;
    this.updateData = null;

    this.totalSize;
    this.remaining = null;
    this.amountToWrite;
    this.currentPosition;

    this.currentHardwareVersion = "N/A";
    this.softwareVersion = "N/A";
    this.latestCompatibleSoftware = "N/A";

    this.characteristicSize = 512;

    this.deviceServiceUuid = "c8659210-af91-4ad3-a995-a58d6fd26146";
    this.batteryPercentageCharacteristicUuid =
      "c8659211-af91-4ad3-a995-a58d6fd26146";
    this.batteryPercentageCharacteristic = null;
    this.batteryStateCharacteristicUuid =
      "c8659212-af91-4ad3-a995-a58d6fd26146";
    this.batteryStateCharacteristic = null;
    this.batteryStateVoltageCharacteristicUuid =
      "c8659213-af91-4ad3-a995-a58d6fd26146";
    this.batteryStateVoltageCharacteristic = null;
    this.spiffsInfoCharacteristicUuid = "c8659214-af91-4ad3-a995-a58d6fd26146";
    this.spiffsInfoCharacteristic = null;
    this.deviceNameCharacteristicUuid = "c8659215-af91-4ad3-a995-a58d6fd26146";
    this.deviceNameCharacteristic = null;

    this.pressureLimit = "N/A";
    this.batteryLevel = [];
    this.batteryState = "N/A";
    this.batteryVoltage = [];
    this.spiffsInfo = [];
    this.deviceName = "N/A";
    this.deviceTimestamp = "N/A";

    this.sensorServiceUuid = "c8659210-af91-4ad3-a995-a58d6fd26147";
    this.sensorLiveCharacteristicUuid = "c8659211-af91-4ad3-a995-a58d6fd26147";
    this.sensorLiveCharacteristic = null;
    this.sensorDatapointCharacteristicUuid =
      "c8659212-af91-4ad3-a995-a58d6fd26147";
    this.sensorDatapointCharacteristic = null;
    this.sensorHistoryCharacteristicUuid =
      "c8659213-af91-4ad3-a995-a58d6fd26147";
    this.sensorHistoryCharacteristic = null;
    this.sensorLiveFlag = false;

    this.maxStoreSensorData = 200;
    this.sensorLiveData = [];
    this.numberOfDatapoints = 100;
    this.sensorHistory = [];
    this.loadingHistoryData = false;
    this.loadingHistoryState = 0;

    this.liveSensorInterval = "N/A";
    this.saveFileInterval = "N/A";
    this.sensorStatus = {};
    this.sensorCorrectionValue = {};

    this.userServiceUuid = "c8659210-af91-4ad3-a995-a58d6fd26148";
    this.lcdOnOffCharacteristicUuid = "c8659211-af91-4ad3-a995-a58d6fd26148";

    this.maxPressureLimitCharacteristicUuid =
      "c8659213-af91-4ad3-a995-a58d6fd26148";
    this.maxPressureLimitCharacteristic = null;
    this.maxPressure = "N/A";

    this.vibrationMotorFetchFlag = true;
    this.vibrationMotorCharacteristicUuid =
      "c8659215-af91-4ad3-a995-a58d6fd26148";
    this.vibrationMotorCharacteristic = null;
    this.vibrationMotorIntensity = 0;
    this.vibrationMotorDuration = 0;


  }

  /* BTConnect
   * Brings up the bluetooth connection window and filters for the esp32
   */
  connect2Device() {
    return new Promise((resolve, reject) => {
      navigator.bluetooth
        .requestDevice({
          filters: [
            {
              services: [this.myESP32]
            }
          ],
          optionalServices: [
            this.otaServiceUuid,
            this.deviceServiceUuid,
            this.sensorServiceUuid,
            this.userServiceUuid
          ]
        })
        .then(device => {
          this.device = device;
          this.connected = true;
          device.addEventListener("gattserverdisconnected", () => {
            this.onDisconnected();
          });
          return device.gatt.connect();
        })
        .then(server => server.getPrimaryService(this.otaServiceUuid))
        .then(service => {
          this.id = service.device.id;
          this.esp32Service = service;
        })
        .then(() => this.getDeviceName())
        .then(() => this.checkVersion())
        .then(() => this.getMaxPressureLimit())
        .then(() => this.StartBatteryDetails())
        .then(() => this.getSpiffsDetails())
        .then(() => this.getVibrationMotorIntensity())
        .then(() => this.getVibrationMotorDuration())
        .then(() => {
          this.setTimestampToDevice()
          return resolve()
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  }

  /* onDisconnected(event)
   * If the device becomes disconnected, prompt the user to reconnect.
   */
  onDisconnected() {
    console.log("onDisconnected");
    this.connected = false;

    exponentialBackoff(this.device)
      .then(() => {
        this.connected = true;
      })
      .catch(error => {
        console.log(error);
        Snackbar.open({
          message:
            this.device.name + " is disconnected, would you like to reconnect?",
          actionText: "Update",
          indefinite: true,
          onAction: () => {
            this.reconnect();
          }
        });
      });
  }

  reconnect() {
    this.device.gatt.connect().then(() => {
      this.connected = true;
      console.log("successfully reconected device");
    });
  }

  getDeviceName() {
    return new Promise(resolve => {
      this.device.gatt
        .getPrimaryService(this.deviceServiceUuid)
        .then(service => {
          return service.getCharacteristic(this.deviceNameCharacteristicUuid);
        })
        .then(characteristic => {
          if (this.deviceNameCharacteristic === null) {
            this.deviceNameCharacteristic = characteristic;
            return
          } else {
            return;
          }
        })
        .then(() => {
          let encoder = new TextEncoder("utf-8");
          return this.RecursiveSend(
            this.deviceNameCharacteristic,
            encoder.encode("name_get=")
          );
        })
        .then(() => this.deviceNameCharacteristic.readValue())
        .then(value => {
          var resp = this.convertArrayBufferToStr(value);
          var responseAnswer = resp.substring(8 + 1);
          this.deviceName = responseAnswer
          return
        })
        .then(() => resolve())
    });
  }

  deviceEvents(event) {
    var resp = this.convertArrayBufferToStr(event.target.value);
    var responseAnswer = resp.substring(8 + 1);
    if (resp.indexOf("name_get=") !== -1) {
      this.deviceName = responseAnswer
    } else if (resp.indexOf("name_set=") !== -1) {
      this.deviceName = responseAnswer
    } else if (resp.indexOf("date_set=") !== -1) {
      this.deviceTimestamp =  parseInt(responseAnswer)
    } else if (resp.indexOf("date_get=") !== -1) {
      this.deviceTimestamp =  parseInt(responseAnswer)
    } else {
      const error = "function not allowed";
      console.log(error);
    }
  }

  /* CheckVersion()
   * Grab most current version from Github and Server, if they don't match, prompt the user for firmware update
   */
  checkVersion() {
    return new Promise((resolve, reject) => {
      if (!this.esp32Service) {
        const error = "no service found";
        reject(error());
      }
      this.esp32Service
        .getCharacteristic(this.versionCharacteristicUuid)
        .then(characteristic => characteristic.readValue())
        .then(value => {
          console.log(value);
          this.currentHardwareVersion =
            "v" + value.getUint8(0) + "." + value.getUint8(1);
          this.softwareVersion =
            "v" +
            value.getUint8(2) +
            "." +
            value.getUint8(3) +
            "." +
            value.getUint8(4);
          return;
        })
        .then(() => {
          this.latestCompatibleSoftware = "v.0.3.1";
          resolve();
        });
    });
    /*
        //Grab our version numbers from Github
        .then(() =>
          fetch(
            "https://raw.githubusercontent.com/sparkfun/ESP32_OTA_BLE_React_WebApp_Demo/master/GithubRepo/version.json"
          )
        )
        .then(function(response) {
          // The API call was successful!
          return response.json();
        })
        .then(function(data) {
          // JSON should be formatted so that 0'th entry is the newest version
          if (this.latestCompatibleSoftware === this.softwareVersion) {
            //Software is updated, do nothing.
          } else {
            var softwareVersionCount = 0;
            this.latestCompatibleSoftware =
              data.firmware[softwareVersionCount]["software"];
            versionFindLoop: while (
              this.latestCompatibleSoftware !== undefined
            ) {
              var compatibleHardwareVersion = "N/A";
              var hardwareVersionCount = 0;
              while (compatibleHardwareVersion !== undefined) {
                compatibleHardwareVersion =
                  data.firmware[softwareVersionCount]["hardware"][
                    hardwareVersionCount++
                  ];
                if (compatibleHardwareVersion === this.currentHardwareVersion) {
                  this.latestCompatibleSoftware =
                    data.firmware[softwareVersionCount]["software"];
                  if (this.latestCompatibleSoftware !== this.softwareVersion) {
                    console.log(this.latestCompatibleSoftware);
                    this.PromptUserForUpdate();
                  }
                  break versionFindLoop;
                }
              }
              softwareVersionCount++;
            }
          }
        })
        .catch(error => {
          console.log(error);
        })
        */
  }

  /* PromptUserForUpdate()
   * Asks the user if they want to update, if yes downloads the firmware based on the hardware version and latest software version and begins sending
   */
  prepareForUpdate() {
    fetch(
      "footsensor/" +
        this.latestCompatibleSoftware +
        "/GithubRepo/" +
        this.currentHardwareVersion +
        ".bin"
    )
      .then(function(response) {
        return response.arrayBuffer();
      })
      .then(data => {
        console.log(data);
        this.updateData = data;
        return this.SendFileOverBluetooth();
      })
      .catch(function(err) {
        console.warn("Something went wrong.", err);
      });
  }

  /* SendFileOverBluetooth(data)
   * Figures out how large our update binary is, attaches an eventListener to our dataCharacteristic so the Server can tell us when it has finished writing the data to memory
   * Calls SendBufferedData(), which begins a loop of write, wait for ready flag, write, wait for ready flag...
   */
  SendFileOverBluetooth() {
    if (!this.esp32Service) {
      console.log("No esp32 Service");
      return;
    }

    this.totalSize = this.updateData.byteLength;
    this.remaining = this.totalSize;
    this.amountToWrite = 0;
    this.currentPosition = 0;
    this.esp32Service
      .getCharacteristic(this.fileCharacteristicUuid)
      .then(characteristic => {
        this.readyFlagCharacteristic = characteristic;
        return characteristic.startNotifications().then(() => {
          this.readyFlagCharacteristic.addEventListener(
            "characteristicvaluechanged",
            event => {
              console.log(event);
              this.SendBufferedData();
            }
          );
        });
      })
      .catch(error => {
        console.log(error);
      });
    console.log("start sending data");
    this.SendBufferedData();
  }

  /* SendBufferedData()
   * An ISR attached to the same characteristic that it writes to, this function slices data into characteristic sized chunks and sends them to the Server
   */
  SendBufferedData() {
    console.log("SendBufferedData, remaining: " + this.remaining);
    if (this.remaining > 0) {
      if (this.remaining >= this.characteristicSize) {
        this.amountToWrite = this.characteristicSize;
      } else {
        this.amountToWrite = this.remaining;
      }
      console.log("amount to write: " + this.amountToWrite);
      this.dataToSend = this.updateData.slice(
        this.currentPosition,
        this.currentPosition + this.amountToWrite
      );
      this.currentPosition += this.amountToWrite;
      this.remaining -= this.amountToWrite;
      console.log(
        "remaining: " +
          this.remaining +
          ", currentPosition: " +
          this.currentPosition +
          ", amountToWrite: " +
          this.amountToWrite
      );
      console.log(this.dataToSend);
      this.esp32Service
        .getCharacteristic(this.fileCharacteristicUuid)
        .then(characteristic => {
          return this.RecursiveSend(characteristic, this.dataToSend);
        })
        .then(() => {
          return store.commit(
            "updateProgress",
            (100 * (this.currentPosition / this.totalSize)).toPrecision(3)
          );
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  getBatteryPercentage() {
    return new Promise(resolve => {
      this.device.gatt
        .getPrimaryService(this.deviceServiceUuid)
        .then(service => {
          return service.getCharacteristic(
            this.batteryPercentageCharacteristicUuid
          );
        })
        .then(characteristic => {
          if (this.batteryPercentageCharacteristic === null) {
            this.batteryPercentageCharacteristic = characteristic;
            return this.batteryPercentageCharacteristic
              .startNotifications()
              .then(() => {
                this.batteryPercentageCharacteristic.addEventListener(
                  "characteristicvaluechanged",
                  event => {
                    this.batteryLevel.push(
                      parseFloat(
                        this.convertArrayBufferToStr(event.target.value)
                      ) / 100
                    );
                  }
                );
              });
          } else {
            return;
          }
        })
        .then(() => {
          return this.batteryPercentageCharacteristic
            .readValue()
            .then(value => {
              this.batteryLevel.push(
                parseFloat(this.convertArrayBufferToStr(value)) / 100
              );
            });
        })
        .then(() => resolve());
    });
  }

  getBatteryState() {
    return new Promise(resolve => {
      this.device.gatt
        .getPrimaryService(this.deviceServiceUuid)
        .then(service => {
          return service.getCharacteristic(this.batteryStateCharacteristicUuid);
        })
        .then(characteristic => {
          if (this.batteryStateCharacteristic === null) {
            this.batteryStateCharacteristic = characteristic;
            return this.batteryStateCharacteristic
              .startNotifications()
              .then(() => {
                this.batteryStateCharacteristic.addEventListener(
                  "characteristicvaluechanged",
                  event => {
                    this.batteryState = this.convertArrayBufferToStr(
                      event.target.value
                    );
                  }
                );
              });
          } else {
            return;
          }
        })
        .then(() => {
          return this.batteryStateCharacteristic.readValue().then(value => {
            console.log(this.convertArrayBufferToStr(value));
            this.batteryState = this.convertArrayBufferToStr(value);
          });
        })
        .then(() => resolve());
    });
  }

  getBatteryVoltage() {
    return new Promise(resolve => {
      this.device.gatt
        .getPrimaryService(this.deviceServiceUuid)
        .then(service => {
          return service.getCharacteristic(
            this.batteryStateVoltageCharacteristicUuid
          );
        })
        .then(characteristic => {
          if (this.batteryStateVoltageCharacteristic === null) {
            this.batteryStateVoltageCharacteristic = characteristic;
            return this.batteryStateVoltageCharacteristic
              .startNotifications()
              .then(() => {
                this.batteryStateVoltageCharacteristic.addEventListener(
                  "characteristicvaluechanged",
                  event => {
                    this.batteryVoltage.push(
                      event.target.value.getFloat32(0, true).toFixed(2)
                    );
                  }
                );
              });
          } else {
            return;
          }
        })
        .then(() => {
          return this.batteryStateVoltageCharacteristic
            .readValue()
            .then(value => {
              this.batteryVoltage.push(value.getFloat32(0, true).toFixed(2));
            });
        })
        .then(() => resolve());
    });
  }

  StartBatteryDetails() {
    return new Promise(resolve => {
      this.getBatteryPercentage()
        .then(() => this.getBatteryState())
        .then(() => this.getBatteryVoltage())
        .then(() => resolve());
    });
  }

  getSpiffsDetails() {
    return new Promise(resolve => {
      this.device.gatt
        .getPrimaryService(this.deviceServiceUuid)
        .then(service => {
          return service.getCharacteristic(this.spiffsInfoCharacteristicUuid);
        })
        .then(characteristic => {
          if (this.spiffsInfoCharacteristic === null) {
            this.spiffsInfoCharacteristic = characteristic;
            return this.spiffsInfoCharacteristic
              .startNotifications()
              .then(() => {
                this.spiffsInfoCharacteristic.addEventListener(
                  "characteristicvaluechanged",
                  event => {
                    this.spiffsInfo = this.convertArrayBufferToStr(
                      event.target.value
                    ).split(",");
                  }
                );
              });
          } else {
            return;
          }
        })
        .then(() => {
          return this.spiffsInfoCharacteristic.readValue().then(value => {
            this.spiffsInfo = this.convertArrayBufferToStr(value).split(",");
          });
        })
        .then(response => {
          console.log(response);
          resolve();
        });
    });
  }

  StartLiveDataStreaming() {
    if ( this.sensorLiveCharacteristic !== null) {
      this.sensorLiveCharacteristic.startNotifications();
    } else {
      this.device.gatt
        .getPrimaryService(this.sensorServiceUuid)
        .then(service => {
          return service.getCharacteristic(this.sensorLiveCharacteristicUuid);
        })
        .then(characteristic => {
          this.sensorLiveCharacteristic = characteristic;
          return this.sensorLiveCharacteristic.startNotifications().then(() => {
            console.log('sensorLiveCharacteristic startNotifications')
            this.sensorLiveCharacteristic.addEventListener(
              "characteristicvaluechanged",
              this.liveSensorDataHandler.bind(this)
            );
          })
        })
        .then(() => {
          this.sensorLiveCharacteristic.readValue().then(value => {
            this.sensorLiveData.push(
              this.convertArrayBufferToStr(value)
                .split(",")
                .map(function(i) {
                  return parseFloat(i, 10);
                })
            );
          });
        })
    }
  }

  liveSensorDataHandler(event) {
    if (this.sensorLiveData.length > this.maxStoreSensorData) {
      this.sensorLiveData.shift();
    }
    var sensorArray = this.convertArrayBufferToStr(event.target.value)
      .split(",")
      .map(function(i) {
        return parseFloat(i, 10);
      });
    this.sensorLiveData.push(sensorArray);
  }

  GetHistoryData() {
    this.device.gatt
      .getPrimaryService(this.sensorServiceUuid)
      .then(service => {
        this.loadingHistoryState = 0;
        this.loadingHistoryData = true;
        return service.getCharacteristic(this.sensorHistoryCharacteristicUuid);
      })
      .then(characteristic => {
        this.sensorHistoryCharacteristic = characteristic;
        return this.sensorHistoryCharacteristic
          .startNotifications()
          .then(() => {
            this.sensorHistoryCharacteristic.addEventListener(
              "characteristicvaluechanged",
              event => {
                var resp = this.convertArrayBufferToStr(event.target.value);
                if (resp === "EOF") {
                  console.log("End of File");
                  this.loadingHistoryData = false;
                  console.log(this.sensorHistory);
                } else {
                  if (resp.includes("points")) {
                    this.numberOfDatapoints = parseInt(
                      resp.replace("points,", "")
                    );
                    console.log(resp);
                  } else {
                    this.loadingHistoryState++;
                    this.sensorHistory.push(resp.split(","));
                    devicedatadb.saveDevice(this.device.id, resp.split(","));
                  }
                  let encoder = new TextEncoder("utf-8");
                  this.RecursiveSend(
                    this.sensorHistoryCharacteristic,
                    encoder.encode("ok")
                  );
                }
              }
            );
          });
      })
      .then(() => {
        let encoder = new TextEncoder("utf-8");
        this.RecursiveSend(
          this.sensorHistoryCharacteristic,
          encoder.encode("start")
        );
      })
      .catch(() => {
        this.loadingHistoryData = false;
      });
  }

  getDatapointData() {
    this.device.gatt
      .getPrimaryService(this.sensorServiceUuid)
      .then(service => {
        return service.getCharacteristic(
          this.sensorDatapointCharacteristicUuid
        );
      })
      .then(characteristic => {
        this.sensorDatapointCharacteristic = characteristic;
        return this.sensorDatapointCharacteristic
          .startNotifications()
          .then(() => {
            this.sensorDatapointCharacteristic.addEventListener(
              "characteristicvaluechanged",
              event => {
                var resp = this.convertArrayBufferToStr(event.target.value);
                this.sensorHistory.push(resp.split(","));
                console.log("new datapoint => " + resp);
                devicedatadb.saveDevice(this.device.id, resp.split(","));
              }
            );
          });
      });
  }

  getMaxPressureLimit() {
    return new Promise(resolve => {
      this.device.gatt
        .getPrimaryService(this.userServiceUuid)
        .then(service => {
          return service.getCharacteristic(
            this.maxPressureLimitCharacteristicUuid
          );
        })
        .then(characteristic => {
          if (this.maxPressureLimitCharacteristic === null) {
            this.maxPressureLimitCharacteristic = characteristic;
            return this.maxPressureLimitCharacteristic
              .startNotifications()
              .then(() => {
                this.maxPressureLimitCharacteristic.addEventListener(
                  "characteristicvaluechanged",
                  this.sensorEvents.bind(this)
                );
              });
          } else {
            return;
          }
        })
        .then(() => {
          let encoder = new TextEncoder("utf-8");
          return this.RecursiveSend(
            this.maxPressureLimitCharacteristic,
            encoder.encode("pmax_get=")
          );
        })
        .then(() => resolve());
    });
  }

  setTimestampToDevice() {
    this.device.gatt.getPrimaryService(this.deviceServiceUuid)
        .then(service => {
          return service.getCharacteristic(this.deviceNameCharacteristicUuid);
        })
      .then(characteristic => {
        this.deviceNameCharacteristic = characteristic;
      })
      .then(() => {
        let encoder = new TextEncoder("utf-8");
        var timestamp = new Date().getTime()
        timestamp = timestamp / 1000
        this.deviceNameCharacteristic.writeValue(
          encoder.encode("date_set=" + timestamp)
        );
      });
  }

  setMaxPressureLimit(newLimit) {
    this.device.gatt
      .getPrimaryService(this.userServiceUuid)
      .then(service => {
        return service.getCharacteristic(
          this.maxPressureLimitCharacteristicUuid
        );
      })
      .then(characteristic => {
        this.maxPressureLimitCharacteristic = characteristic;
      })
      .then(() => {
        let encoder = new TextEncoder("utf-8");
        this.maxPressureLimitCharacteristic.writeValue(
          encoder.encode("pmax_set=" + newLimit)
        );
        this.maxPressure = newLimit;
      });
  }

  getVibrationMotorIntensity() {
    this.vibrationMotorFetchFlag = false;
    return new Promise(resolve => {
      this.device.gatt
        .getPrimaryService(this.userServiceUuid)
        .then(service => {
          return service.getCharacteristic(
            this.vibrationMotorCharacteristicUuid
          );
        })
        .then(characteristic => {
          this.vibrationMotorCharacteristic = characteristic;
          this.vibrationMotorCharacteristic = characteristic;
          return this.vibrationMotorCharacteristic
            .startNotifications()
            .then(() => {
              this.vibrationMotorCharacteristic.addEventListener(
                "characteristicvaluechanged",
                this.vibrationMotorEvents.bind(this)
              );
            });
        })
        .then(() => {
          this.vibrationMotorFetchFlag = false;
          let encoder = new TextEncoder("utf-8");
          this.RecursiveSend(
            this.vibrationMotorCharacteristic,
            encoder.encode("i_get=")
          );
          return;
        })
        .then(() => resolve());
    });
  }

  sensorEvents(event) {
    var resp = this.convertArrayBufferToStr(event.target.value);
    var responseAnswer = resp.substring(8 + 1);
    if (resp.indexOf("lint_get=") !== -1) {
      this.liveSensorInterval = parseInt(responseAnswer);
    } else if (resp.indexOf("fint_get=") !== -1) {
      this.saveFileInterval = parseInt(responseAnswer);
    } else if (resp.indexOf("corr_get=") !== -1) {
      this.sensorCorrectionValue[
        responseAnswer.substring(0, responseAnswer.indexOf(","))
      ] = parseInt(responseAnswer.substring(responseAnswer.indexOf(",") + 1));
    } else if (resp.indexOf("pmax_set=") !== -1) {
      this.pressureLimit = parseFloat(responseAnswer);
    } else if (resp.indexOf("pmax_get=") !== -1) {
      this.pressureLimit = parseFloat(responseAnswer);
    } else if (resp.indexOf("sens_stat=") !== -1) {
      this.sensorStatus[
        responseAnswer.substring(0, responseAnswer.indexOf(","))
      ] = parseInt(responseAnswer.substring(responseAnswer.indexOf(",") + 1));
    } else {
      const error = "function not allowed";
      console.log(error);
    }
  }

  vibrationMotorEvents(event) {
    var resp = this.convertArrayBufferToStr(event.target.value);
    if (resp.indexOf("i_get=") !== -1) {
      this.vibrationMotorIntensity = parseInt(resp.substring(6));
    } else if (resp.indexOf("d_get=") !== -1) {
      this.vibrationMotorDuration = parseInt(resp.substring(6));
    } else {
      const error = "function not allowed";
      console.log(error);
    }
  }

  getVibrationMotorDuration() {
    return new Promise(resolve => {
      this.device.gatt
        .getPrimaryService(this.userServiceUuid)
        .then(service => {
          return service.getCharacteristic(
            this.vibrationMotorCharacteristicUuid
          );
        })
        .then(characteristic => {
          this.vibrationMotorCharacteristic = characteristic;
          return this.vibrationMotorCharacteristic
            .startNotifications()
            .then(() => {
              this.vibrationMotorCharacteristic.addEventListener(
                "characteristicvaluechanged",
                this.vibrationMotorEvents.bind(this)
              );
            });
        })
        .then(() => {
          this.vibrationMotorFetchFlag = false;
          let encoder = new TextEncoder("utf-8");
          this.RecursiveSend(
            this.vibrationMotorCharacteristic,
            encoder.encode("d_get=")
          );
        })
        .then(() => resolve());
    });
  }

  setVibrationMotorIntensity(newIntensity) {
    if (this.vibrationMotorCharacteristic !== null) {
      let encoder = new TextEncoder("utf-8");
      this.vibrationMotorCharacteristic.writeValue(
        encoder.encode("i_set=" + newIntensity)
      );
    }
  }

  setVibrationMotorDuration(newDuration) {
    if (this.vibrationMotorCharacteristic !== null) {
      let encoder = new TextEncoder("utf-8");
      this.RecursiveSend(
        this.vibrationMotorCharacteristic,
        encoder.encode("d_set=" + newDuration)
      );
    }
  }

  lcdOnOff() {
    this.device.gatt
      .getPrimaryService(this.userServiceUuid)
      .then(service => {
        return service.getCharacteristic(this.lcdOnOffCharacteristicUuid);
      })
      .then(characteristic => {
        let encoder = new TextEncoder("utf-8");
        this.RecursiveSend(characteristic, encoder.encode("lcd_off"));
      });
  }

  stopLiveDataStreaming() {
    if (this.sensorLiveCharacteristic != null) {
      this.sensorLiveCharacteristic.stopNotifications();
    }
  }

  convertArrayBufferToStr(arrayBuffer) {
    var stringDecoded = new TextDecoder().decode(arrayBuffer);
    return stringDecoded;
  }

  convertArrayBufferToNumber(buffer) {
    const bytes = new Uint16Array(buffer);
    const dv = new DataView(bytes.buffer);
    if (dv.byteLength > 0) {
      return dv.getUint16(0, true);
    }
  }

  /* resursiveSend()
   * Returns a promise to itself to ensure data was sent and the promise is resolved.
   */
  RecursiveSend(characteristic, data) {
    if (typeof data === "string") {
      data = this.str2ab(data);
    }
    return characteristic.writeValue(data).catch(() => {
      this.RecursiveSend(characteristic, data);
    });
  }

  str2ab(str) {
    var buf = new ArrayBuffer(str.length * 2); // 2 bytes for each char
    var bufView = new Uint16Array(buf);
    for (var i = 0, strLen = str.length; i < strLen; i++) {
      bufView[i] = str.charCodeAt(i);
    }
    return buf;
  }
}

const delay = retryCount =>
  new Promise(resolve => setTimeout(resolve, retryCount * 2000));

const exponentialBackoff = async (
  toTryFunc = null,
  retryCount = 0,
  lastError = null
) => {
  console.log("try number: " + retryCount);
  if (retryCount > 5) throw new Error(lastError);
  try {
    return await toTryFunc.gatt.connect();
  } catch (e) {
    await delay(retryCount);
    return exponentialBackoff(toTryFunc, retryCount + 1, e);
  }
};

export default BluetoothManager;
